.custom-context-menu {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  padding: 8px 0;
  z-index: 1000;
  min-width: 180px;
  font-size: 14px;
}

.context-menu-item {
  padding: 8px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.87);
  transition: background-color 0.2s ease;
  position: relative;
}

.context-menu-item:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.context-menu-item:active {
  background-color: rgba(0, 0, 0, 0.08);
}

/* Add dividers between items */
.context-menu-item:not(:last-child)::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 8px;
  right: 8px;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.08);
}
