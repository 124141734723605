.risk-cell-approve {
  font-weight: 900;
  border: 1px solid rgb(255, 255, 255);
  background-color: rgba(66, 209, 22, 0.569);
}

.risk-cell-decline {
  font-weight: 900;
  border: 1px solid rgb(255, 255, 255);
  background-color: rgba(255, 0, 0, 0.48);
}

.risk-cell-a {
  font-weight: 900;
  border: 1px solid rgb(255, 255, 255) !important;
  background-color: rgba(14, 185, 28, 0.514) !important;
}

.risk-cell-b {
  font-weight: 900;
  border: 1px solid rgb(255, 255, 255) !important;
  background-color: rgba(0, 153, 255, 0.514) !important;
}

.risk-cell-c {
  font-weight: 900;
  border: 1px solid rgb(255, 255, 255) !important;
  background-color: rgba(255, 123, 0, 0.5) !important;
}

.risk-cell-d {
  font-weight: 900;
  border: 1px solid rgb(255, 255, 255) !important;
  background-color: rgba(140, 0, 255, 0.466) !important;
}

.risk-cell-e {
  font-weight: 900;
  border: 1px solid rgb(255, 255, 255) !important;
  background-color: rgba(255, 11, 11, 0.5) !important;
}

.risk-cell-f {
  font-weight: 900;
  border: 1px solid rgb(255, 255, 255) !important;
  background-color: rgba(247, 34, 212, 0.603) !important;
}

.risk-cell-empty {
  font-weight: 900;
  border: 1px solid rgb(255, 255, 255) !important;
  background-color: rgba(168, 168, 168, 0.5) !important;
}

.risk-border-cell {
  border: "none";
}

.risk-row {
  border: "none";
}

.risk-header .MuiDataGrid-columnSeparator {
  display: none !important;
}
